<template>
	<div id="Editorial-AwardList" class="main-wrap Editorial-wrap">
		<div class="flex between w100">
			<div class="left-box"><MainMenu :title="step==1?'编委初评':'终评'" :list="menus"/></div>

			<div class="w850" style="margin:unset">
				<div class="title-h2" v-if="currentAwardTypeData.ename">{{currentAwardTypeData.ename}}</div>
				<div class="title-h1" v-if="currentAwardTypeData.name">{{L(currentAwardTypeData.name)}}</div>
				<div class="ta-c mt-10 mb-10" v-if="isLoading">
					<img src="/loading_d.gif"/>
				</div>
				<div class="awardType-list flex between middle wrap">
					<div v-for="item in currentList" :key="'aid_'+item.id" class="item"  @click="doSelectAward(item)">
						<div class="box">
							<CoverWrap border :src="item.content.images[0].path + '?x-oss-process=image/resize,w_400'" width="100%" height="100%"/>
						</div>
						<div class="info flex between middle">
							<div class="title one-line b">{{L(item.content.title)}}</div>
							<div class="status s ta-r">
								<template v-if="step == 1">
									<template v-if="scoreMap[item.id]">
										<span v-if="scoreMap[item.id].isRecomment" class="scoreBtn score-recomment active">{{L("★推荐")}}</span>
										<span v-else class="scoreBtn active" :class="'score-'+scoreMap[item.id].score">{{L(scoreMap[item.id].score+"分")}}</span>
									</template>
									<template v-else>
										<span class="scoreBtn score-empty active">{{L("未评分")}}</span>
									</template>
								</template>
								<template v-else>
									<template v-if="scoreMap[item.id]">
										<span class="scoreBtn " :class="{active:!!scoreMap[item.id].voteType,voted:scoreMap[item.id].voteType==1,unvote:scoreMap[item.id].voteType==-1}">{{L(scoreMap[item.id].voteType==1&&"已投票"||scoreMap[item.id].voteType==-1&&"不投票"||'待处理')}}</span>
									</template>
									<template v-else>
										<span class="scoreBtn score-empty active">{{L("待处理")}}</span>
									</template>
								</template>
								
							</div>
						</div>
						
						
					</div>
					<div class="item empty" v-for="(item,index) in 10" :key="index"></div>
				</div>
				
				<div class="mt-20"></div>
				<div class="mt-20"></div>
				
				<div class="score-info b">
					<template v-if="step==1">
						{{L("已打分项目")}}： <span>{{scoredTotal}}</span> {{L("个")}}
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
						{{L("已推荐项目")}}：<span>{{recommentTotal}}</span> {{L("个")}}
					</template>
					<template v-else >
						{{L('请为“年度主题单元”选出20件作品，目前已投： ')}}<span>{{votedTotal}}</span> {{L("件")}}
					</template>
				</div>
				<div class="line"></div>
				<div class="mt-20"></div>
				<div class="mt-20"></div>
				<Pagination
				  :change="doChangePage"
				  :page-size.sync="pageSize"
				  :page.sync="page"
				  :total.sync="total">
				</Pagination>
				
				<div class="mt-20"></div>
				<div class="action-box flex right">
					<div class="button" @click="toConfirmPage">{{L("下一步，确认投票清单")}}</div>
				</div>
				<div class="mt-20"></div>
			
			</div>
		</div>	
	</div>
</template>
<script>
import MainMenu from '/src/views/MainMenu';
import Pagination from '/src/components/Pagination.vue';
import CoverWrap from '/src/components/CoverWrap.vue';
export default {
  name: 'Editorial-AwardList',
  components: {
    MainMenu,
		Pagination,
		CoverWrap
  },
  data(){
    return {
			step:this.$route.params.step || 0,
			awardType:0,
			currentAwardTypeData:{},
			menus:[],
			list:[],
			currentList:[],
			page:1,
			pageSize:12,
			total:0,
			scoreMap:{},
			recommentTotal:0,
			scoredTotal:0,
			votedTotal:0,
			isLoading:true
    };
  },
	watch:{
		$route(){
			this.step = this.$route.params.step || 0;
			this.initData();
		}
	},
  async mounted () {
		// console.log(this.$root.userData.config.groupType);
		// console.log(JSON.stringify(this.$root.awardTypeList));
		
		
		await this.initData();
		this.isLoading = false;
  },
  methods: {
		async doChangePage(page){
			this.page = page;
			this.currentList = this.list.slice((this.page-1)*this.pageSize,this.page*this.pageSize );
			// await this.getList();
		},
		doSelectAward(item){
			this.$root.toPage(`/Work/Content/${this.$root.md5(item.id)}/${item.id}/${this.step}`)
		},
		toConfirmPage(){
			this.$root.toPage('/Editorial/AwardConfirm/'+this.step+'/'+this.awardType)
			
		},
		async initData(){
			let userData = await this.$root.initUserData();
			
			let awardTypeList = [];
			if(userData.config.groupType == 'A' || userData.config.groupType == 'B'|| userData.config.groupType == 'C'){
				awardTypeList = this.$root.awardTypeList.filter(item=>this.$root.CONFIG['groupType'+userData.config.groupType].includes(item.id))
				// awardTypeList = this.$root.awardTypeList.filter(item=>item.groupType == this.$root.userData.config.groupType);
				
			} else {
				awardTypeList = this.$root.awardTypeList;
				
			}
			
			let menus = [];
			if(this.step == 1){
				menus = awardTypeList.map(item=>{
					return {title:this.L(item.name),path:'/Editorial/AwardList/'+this.step+'/'+item.id}
				});
				
				const awardType = parseInt(this.$route.params.id);
				this.awardType = awardType;
				
				let target = this.$root.awardTypeList.find(item=>item.id == this.awardType);
				if(target){
					this.$set(this,'currentAwardTypeData',target);
					await this.getList();
				}
				
			} else {
				await this.getList();
			}
			this.$set(this,'menus',menus);
			
			
			
			
			await this.initScoreMap();
			
		},
		async initScoreMap(){
			let userData = await this.$root.initUserData();
			let scoreMap = {};
			let recommentTotal = 0;
			let scoredTotal = 0;
			let votedTotal = 0
			if(this.step == 1){
				if(userData && userData.config && userData.config.firstScoreList){
					userData.config.firstScoreList.filter(item=>item.aid==this.awardType&&this.list.find(item2=>item2.id==item.wid)).forEach(item=>{
						scoreMap[item.wid] = this.$root.copy(item);
						if(item.isRecomment){
							recommentTotal++;
						} else{
							scoredTotal ++;
						}
						
					})
				}
			} else{
				if(userData && userData.config && userData.config.secondScoreList){
					userData.config.secondScoreList.filter(item=>this.list.find(item2=>item2.id==item.wid)).forEach(item=>{
						scoreMap[item.wid] = this.$root.copy(item);
						if(item.voteType && item.voteType == 1){
							votedTotal ++;
						}
						
					})
				}
			}
			
			this.$set(this,'scoreMap',scoreMap);
			this.recommentTotal = recommentTotal;
			this.scoredTotal = scoredTotal;
			this.votedTotal = votedTotal;
		},
		async getList(){
			
			let params = {
				page: 1,// this.page,
				pageSize: 999//this.pageSize,
			};
			if(this.step==1){
				params.awardWorkStatus = 11; // 已入选初评
				params.awardType = this.awardType;
			}else {
				// params.awardWorkStatus = 11;
				params.awardWorkStatus = 21;// 已入选终评
			}
			
			params = this.$root.object2urlparams(params);
			let res = await this.request.get(`api/v1/awardWork/paging?${params}`);
			if(res){
				this.total = res.total;
				// this.page = res.page;
				// this.pageSize = res.pageSize;
				let list = res.rows || [];
				list.forEach(item=>{
					try{
						item.content = JSON.parse(item.content)
					}catch(e){
						item.content = {};
					}
				})
				this.list = list;
				this.doChangePage(1);
			}
		}
  }
}
</script>

<style scoped lang="less">
.line{
	height:1px;
	margin-top:5px;
	background-color: #000;
}
.score-info{
	font-size:14px;
	span{
		color:#FF08FF;
	}
}
.left-box{
	// position: absolute;
	flex-shrink: 0;
	// left:45px;
	width:150px;
}
.awardType-list{
	.item{
		width:calc((100% - 80px) / 3);
		cursor: pointer;
		min-width: 150px;
		margin-bottom:40px;
		.box{
			background-color: #EDEDED;
			text-align: center;
			font-size:20px;
			width: 100%;
			padding: calc(100% / 10 * 3) 0;
			position: relative;
			/deep/.cover{
				position: absolute;
				top:0;
				left:0;
				right:0;
				bottom:0;
			}
		}
		.info{
			padding:8px 0;
			font-size:15px;
			.title{
				width:100%;
			}
			.status{
				min-width:50px;
				.scoreBtn{
					font-size:12px;
					line-height: 28px;
					padding:0 4px;
				}
				// span{
				// 	font-size:12px;
				// 	border-radius: 6px;
				// 	border:1px solid #000;
				// 	padding:0 2px;
				// }
			}
		}
		
		&.empty {
			height: 0px;
			margin-bottom:0;
			width:calc((100% - 80px) / 3);
			background-color: transparent;
		}	
	}
	
}
</style>